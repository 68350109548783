<template>
  <div class="d-flex p-3 border rounded bg-light mb-3">
    <div
      class="mr-4"
      style="width: 180px;"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Название
      </div>
      <div class="font-weight-bold">
        {{ region.name }}
      </div>
    </div>

    <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Код региона
      </div>
      <div>
        {{ region.code }}
      </div>
    </div>

    <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Конституционный код
      </div>
      <div>
        {{ region.constitutionCode }}
      </div>
    </div>

    <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Таймзона
      </div>
      <div>
        {{ region.timezone }}
      </div>
    </div>

    <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        ОКАТО
      </div>
      <div>
        {{ region.okatoCode }}
      </div>
    </div>

    <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        ТМК
      </div>
      <div>
        {{ region.isTmk ? 'Да' : 'Нет' }}
      </div>
    </div>

    <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Дисмониторинг
      </div>
      <div>
        {{ region.isDismonitoring ? 'Да' : 'Нет' }}
      </div>
    </div>

    <!-- <div
      class="mr-4"
    >
      <div
        class="text-secondary mb-1"
        style="font-size: 0.8rem"
      >
        Удален
      </div>
      <div>
        {{ !region.isActive ? 'Да' : 'Нет' }}
      </div>
    </div> -->

    <div class="d-flex align-items-center ml-auto">
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :disabled="!region.isActive"
        @click="openRegionEditModal"
      >
        <b-icon icon="pencil-square" />
      </b-button>

      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        size="sm"
        :disabled="!region.isActive"
        @click="openDeleteModal"
      >
        <b-icon icon="trash" />
      </b-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import { locationService } from '@/services';
import {
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

export default {
  name: 'RegionItem',
  components: {},
  props: {
    region: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    async deleteRegion() {
      try {
        await locationService.deleteRegion(this.region.id);
        showSuccessCustomMessage('Регион успешно удален!');
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось удалить регион');
        throw new Error(err);
      }
      Bus.$emit('regions:update');
    },
    openDeleteModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Удаление региона',
            cb: this.deleteRegion,
            cbArgs: [this.region.id],
          },
        },
      );
    },
    openRegionEditModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'RegionEditModal',
        props: {
          regionData: this.region,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.regions-list {
  height: calc(100vh - 180px);
  overflow: auto;
}
</style>
